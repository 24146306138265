import { Link } from 'gatsby';
import React from 'react';
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../components/layout";
import Seo from "../components/seo";

function RequestForm() {
    return (
        <Layout>
            <ScrollUpButton ContainerClassName="custom-scroll-button" />
            <Seo title="Request Form" />
            <Container className="py-5">
                <Row className="justify-content-center text-center mb-lg-5 mb-4">
                    <Col md={8} lg={6}>
                        <div className="section-title mb-2">
                            <h2>Request Form</h2>
                            <p>Select a correct form depending on your request. Our team will contact you within 1-2 days working days by email or point of contact</p>
                        </div>
                    </Col>
                </Row>
                

                <Row className="justify-content-center text-center mb-lg-5 mb-3" style={{minHeight: '250px'}}>
                    <Col lg={8}>
                        <Dropdown className='request-dropdown'>
                            <Dropdown.Toggle variant="default" className='d-flex align-items-center justify-content-between' id="dropdown-basic">
                                - Partnership
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Link className='dropdown-item' href="/">Partnership</Link>
                                <Link className='dropdown-item' href="/">Staking</Link>
                                <Link className='dropdown-item' href="/">Report a Scam</Link>
                                <Link className='dropdown-item' href="/">Marketing & Proposal</Link>
                                <Link className='dropdown-item' href="/">Career</Link>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                
            </Container>
        </Layout>
    );
}

export default RequestForm;